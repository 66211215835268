import { Bugsnag } from '@air/utils-error';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { PropsWithChildren, useEffect, useRef } from 'react';

export interface LogRocketProps extends PropsWithChildren<{}> {}

export const LogRocketContainer = ({ children }: LogRocketProps) => {
  const loadedRef = useRef(false);

  useEffect(() => {
    if (window.location.hostname === 'app.air.inc' && !loadedRef.current) {
      const logRocketKey = process.env.NEXT_PUBLIC_LOGROCKET;

      if (!logRocketKey) {
        return console.error('No LogRocket key was provided! Missing the NEXT_PUBLIC_LOGROCKET environment variable.');
      }

      LogRocket.init(logRocketKey, {
        dom: {
          /** @see https://app.logrocket.com/0mjm6m/air/settings/privacy/ */
          inputSanitizer: true,
        },
        network: {
          requestSanitizer: (request) => {
            // Don't share payment info
            if (request.url?.includes('stripe')) {
              return null;
            }

            // Don't share Authorization header
            request.headers.Authorization = undefined;

            // Hide body from auth-related requests
            if (
              request.body?.includes('password') ||
              request.body?.includes('Password') ||
              request.body?.includes('AuthParameters')
            ) {
              request.body = undefined;
            }

            return request;
          },
        },
      });

      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL((sessionURL) => {
        if (!!window.Intercom) {
          console.info('LogRocket - integrating intercom');
          window.Intercom('trackEvent', 'LogRocket', { sessionURL });
        }

        if (!!window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'LogRocket',
            eventAction: sessionURL,
          });
        }
      });

      loadedRef.current = true;
      window.hasLogRocketInitialized = true;
      if (Bugsnag.isStarted()) {
        Bugsnag.addOnError((event) => {
          event.addMetadata('LogRocket', {
            sessionURL: LogRocket.sessionURL,
          });
        });
      }
    }
  }, []);

  return <>{children}</>;
};
